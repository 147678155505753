import * as React from "react"
import { graphql } from "gatsby"

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation } from 'gatsby-plugin-react-i18next'

import SmoothScroll from "../components/layout/smoothScroll";

import Layout from '../components/layout/layout'
import Seo from '../components/seo'
import MenuGlobal from '../components/menu'
import Slogan from '../components/slogan3'
import ProjetosDestaques from "../components/homepage/projetosDestaque";
import Branding from '../components/homepage/branding'
import BrandingMobile from '../components/homepage/brandingMobile'
import Team from '../components/homepage/team'
import ClientsLogos from '../components/homepage/clientsLogos'

import '../sass/app.scss';
import '../sass/homepage.scss';

const IndexPage = ({ location, data }) => {

  const breakpoints = useBreakpoint();
  const publicsUrl = data.allFile

  const { t } = useTranslation()
  const dataGlobal = t("global", { returnObjects: true })
  const dataIndex = t("homepage", { returnObjects: true })

  return (
    <Layout home mobile={useBreakpoint().mobile} post={null} data={dataGlobal} page={dataIndex.page} seo={dataIndex.seo}>

      {breakpoints.mobile ?
        <>
          <MenuGlobal video src="https://news.invisual.pt/wp/wp-content/uploads/2024/11/Homepage-mobile.mp4"
            global={dataGlobal} homepage mobile location={location} />
          <SmoothScroll>

            <Slogan data={dataIndex.firstsloganmobile} index={0} mobile />

            <ProjetosDestaques data={dataIndex.destaques.first} index={1} publicsUrl={publicsUrl.edges} mobile />

            <div className="container-bg-over-video bg-black">

              <ProjetosDestaques data={dataIndex.destaques.second} index={2} mobile />

              <BrandingMobile data={dataIndex.branding} />

              <ProjetosDestaques data={dataIndex.destaques.third} index={3} mobile />

              <div className="container-margin-small-height"></div>

              <ProjetosDestaques data={dataIndex.destaques.fourth} index={4} mobile />

              <Team data={dataIndex.team} publicsUrl={publicsUrl.edges} mobile />

              <Slogan data={dataIndex.secondslogan} index={1} mobile />

              <ClientsLogos data={dataIndex.team} publicsUrl={publicsUrl.edges} mobile />

              <div className="container-scroll-heigth" />
            </div>
          </SmoothScroll>
        </>
        :
        <>
          <MenuGlobal video src="https://news.invisual.pt/wp/wp-content/uploads/2024/11/Homepage-Desktop.mp4"
            global={dataGlobal} homepage location={location} teste />
          <SmoothScroll>

            <Slogan data={dataIndex.firstslogan} index={0} />

            <ProjetosDestaques data={dataIndex.destaques.first} index={1} publicsUrl={publicsUrl.edges} />

            <div className="container-bg-over-video bg-black">

              <ProjetosDestaques data={dataIndex.destaques.second} index={2} />

              <Branding data={dataIndex.branding} />

              <ProjetosDestaques data={dataIndex.destaques.third} index={3} />

              <div className="container-margin-small-height"></div>

              <ProjetosDestaques data={dataIndex.destaques.fourth} index={4} />

              <div className="container-margin-small-height"></div>

              <Team data={dataIndex.team} publicsUrl={publicsUrl.edges} />

              <Slogan data={dataIndex.secondslogan} index={1} />

              <ClientsLogos data={dataIndex.team} publicsUrl={publicsUrl.edges} />


              <div className="container-scroll-heigth" />
            </div>

          </SmoothScroll>
        </>
      }

    </Layout >

  )
}

export default IndexPage


export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["global", "homepage"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
    allFile {
      edges {
        node {
          relativePath
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`